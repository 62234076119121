import React, { useContext, useReducer } from "react";
import {
  HIDE_WELCOME_PANEL,
  LOADING_COMPLETE,
  DISPLAY_ALERT,
  DISPLAY_CALENDLY_MODAL,
  DISPLAY_CONTACT_MODAL,
  HIDE_ALERT,
  HIDE_CALENDLY_MODAL,
  HIDE_CONTACT_MODAL,
  MAIL_SENT_SUCCESS,
  MAIL_SENT_ERROR,
} from "../reducer/action.js";
import reducer from "../reducer/reducer.js";

const initialState = {
  curso: "",
  name: "",
  isBusiness: false,
  isCalendlyModalVisible: false,
  calendlyCurrentLink: "",
  isContactModalVisible: false,
  showAlert: false,
  alertText: "",
  alertType: "",
  isLoading: true,
  isFirstVisit: true,
};

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const displayAlert = () => {
    dispatch({ type: DISPLAY_ALERT });
    hideAlert();
  };

  const hideAlert = () => {
    setTimeout(() => {
      dispatch({ type: HIDE_ALERT });
    }, 3000);
  };

  const openCalendlyModal = (calendlyLink) => {
    dispatch({ type: DISPLAY_CALENDLY_MODAL, payload: { calendlyLink } });
  };

  const closeCalendlyModal = () => {
    dispatch({ type: HIDE_CALENDLY_MODAL });
  };

  const openContactModal = () => {
    dispatch({ type: DISPLAY_CONTACT_MODAL });
    // if (value !== "fisioterapia-y-osteopatia-para-empresas") {
    //     updateCurso(value)
    // }
  };

  const closeContactModal = () => {
    dispatch({ type: HIDE_CONTACT_MODAL });
  };

  //   const updateCurso = (curso) => {
  //     dispatch({ type: UPDATE_CURSO, payload: { curso } });
  //   };

  //   const confirmCursoSent = (curso) => {
  //     dispatch({ type: BOOK_CURSO_SUCCESS, payload: { curso } });
  //     setTimeout(() => {
  //       closeContactModal();
  //     }, 5000);
  //   };

  const confirmMailSent = () => {
    dispatch({ type: MAIL_SENT_SUCCESS });
    setTimeout(() => {
      closeContactModal();
    }, 5000);
  };

  const confirmMailError = () => {
    dispatch({ type: MAIL_SENT_ERROR });
  };

  const completeLoading = () => {
    dispatch({ type: LOADING_COMPLETE });
  };

  const hideWelcomePanel = () => {
    dispatch({ type: HIDE_WELCOME_PANEL });
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        completeLoading,
        displayAlert,
        hideAlert,
        openCalendlyModal,
        closeCalendlyModal,
        openContactModal,
        closeContactModal,
        confirmMailSent,
        confirmMailError,
        hideWelcomePanel,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, useAppContext };
