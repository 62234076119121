import {
  HIDE_WELCOME_PANEL,
  LOADING_COMPLETE,
  DISPLAY_ALERT,
  HIDE_ALERT,
  DISPLAY_CALENDLY_MODAL,
  HIDE_CALENDLY_MODAL,
  DISPLAY_CONTACT_MODAL,
  HIDE_CONTACT_MODAL,
  // UPDATE_CURSO,
  // BOOK_CURSO_BEGIN,
  // BOOK_CURSO_SUCCESS,
  MAIL_SENT_SUCCESS,
  MAIL_SENT_ERROR,
} from "./action";

const reducer = (state, action) => {
  if (action.type === DISPLAY_ALERT) {
    return {
      ...state,
      showAlert: true,
    };
  }

  if (action.type === HIDE_ALERT) {
    return {
      ...state,
      showAlert: false,
    };
  }

  if (action.type === DISPLAY_CALENDLY_MODAL) {
    return {
      ...state,
      isCalendlyModalVisible: true,
      calendlyCurrentLink: action.payload.calendlyLink,
    };
  }

  if (action.type === HIDE_CALENDLY_MODAL) {
    return {
      ...state,
      isCalendlyModalVisible: false,
      calendlyCurrentLink: "",
    };
  }

  if (action.type === DISPLAY_CONTACT_MODAL) {
    return {
      ...state,
      isContactModalVisible: true,
    };
  }

  if (action.type === HIDE_CONTACT_MODAL) {
    return {
      ...state,
      isContactModalVisible: false,
      empresa: "",
      isBusiness: false,
      name: "",
      alertText: "",
      alertType: "",
      email: "",
      phone: "",
    };
  }

  // if (action.type === BOOK_CURSO_BEGIN) {
  //   return {
  //     ...state,
  //     isLoading: true,
  //   };
  // }
  // if (action.type === BOOK_CURSO_SUCCESS) {
  //   return {
  //     ...state,
  //     curso: action.payload.curso,
  //     alertText: `Gracias por tu interes en el curso ${action.payload.curso}. Te hemos enviado un correo de confirmacion y nos pondremos en contacto contigo en breve`,
  //     alertType: "success",
  //   };
  // }

  if (action.type === MAIL_SENT_ERROR) {
    return {
      ...state,
      alertText:
        "El correo no ha podido enviarse. Disculpe por las molestias. Por favor contáctenos por telefono",
      alertType: "danger",
    };
  }

  if (action.type === MAIL_SENT_SUCCESS) {
    return {
      ...state,
      alertText:
        "El correo se ha enviado correctamente. Nos pondremos en contacto contigo en breve. ¡ Gracias !",
      alertType: "success",
    };
  }

  if (action.type === LOADING_COMPLETE) {
    return {
      ...state,
      isLoading: false,
    };
  }

  if (action.type === HIDE_WELCOME_PANEL) {
    return {
      ...state,
      isFirstVisit: false,
    };
  }
};

export default reducer;
