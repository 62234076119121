// import { Logo } from '../components/index.js'
import dna from '../assets/images/dna.svg'

import { motion } from 'framer-motion'

import styled from 'styled-components'

const Wrapper = styled(motion.div)`
/* width:100vw;
height:100vh; */
display:flex;
flex-direction:column;
gap:2rem;
align-items:center;
justify-content:center;

.logo{
    max-width:100px;
}

`

// const anim = {
//     initial: {
//         opacity: 0
//     },
//     animate: {
//         opacity: 1,
//         transition: {
//             duration: 1
//         }
//     }
// }

// const animLogo = {
//     initial: {
//         opacity: 0,
//         y: 200
//     },
//     animate: {
//         opacity: 1,
//         y: 0,

//         transition: { type: "spring", stiffness: 120 }

//     }
// }

const Loading = () => {
    return (

        <Wrapper >
            {/* <Logo variants={animLogo} initial="initial" animate="animate" className='logo'></Logo> */}
            <img src={dna} alt="" />
        </Wrapper>

    )
}
export default Loading